<app-layout></app-layout>

<div class="content">
  <div style="margin-bottom: 30px; display: flex; align-items: center;">
    <label for="filter" style="color: black; font-size: 18px; font-weight: bold; margin-right: 10px;">Filter By:</label>
    <select id="filter" [(ngModel)]="selectedFilter" (change)="onChangeCategory($event.target.value)"  class="dropdownStyles" style="cursor: pointer;">
      <option value="daily">Daily</option>
      <option value="weekly">Weekly</option>
      <option value="monthly">Monthly</option>
      <option value="yearly">Annually</option>
    </select>
  </div>
  
  
  

  <div class="container">
    <div class="card-wrapper">
      <div class="clickable-div registered-customers" routerLink="/manage-profile/list">
        <img class="card-content" src="../../../assets/images/homeowner.png" alt="Devices Icon">
        <h3>Registered Customers</h3>
        <p>{{registeredUsers}}</p>
      </div>
      <div class="clickable-div revenue-generated" style="cursor: default;">
        <img class="card-content" src="../../../assets/images/serviceprovider.png" alt="Devices Icon">
        <h3>Revenue Generated</h3>
        <p>0</p>
      </div>
    </div>
  </div>
  
</div>



import { Component, OnDestroy, OnInit } from '@angular/core';
import { HeaderService } from 'src/app/shared/services/header.service';
import { DashboardService } from '../services/dashboard.service';
import { AuthService } from 'src/app/auth/services/auth.service';
import { ActivatedRoute } from '@angular/router';
import { PageChangedEvent } from 'ngx-bootstrap/pagination';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit, OnDestroy {
  public canvas: any;
  public ctx: any;
  public chart: any;
  dashboardData: any = [];
  dashboardDailyData: any;
  graphData: any;
  months: number = 6;
  currentUser: any;
  registeredUsers: any;
  registeredGuest: any;
  registeredHost: any;
  registeredLocal: any;
  totalBookings: any;
  totalRevenue: any;
  formattedNumber: any;

  constructor(
    private _header: HeaderService,
    private dashboardService: DashboardService,
    private authService: AuthService,
    private route: ActivatedRoute,
  ) {}

  page: number;
  itemsPerPage: number = 10;
  searchKey: any;
  mainEmailId: any;
  currentPage: number;
  resultCount: number;
  notFound: boolean = false;
  selectedFilter: string = 'daily';
  startDate: Date;
  endDate: Date;
  reportingList: any = [];

  ngOnInit(): void {
    this._header.welcomeUserMsg.next(true);
    this.currentUser = this.authService.currentUserValue;
    this.route.queryParams.subscribe(params => {
      let paramPage = params['page'];
      this.page = parseInt(params['page']);
      if (typeof paramPage == 'undefined') {
        this.page = 1;
      } else {
        this.page = parseInt(paramPage);
      }
    });
    if (this.page) {
      this.currentPage = this.page;
    }

    this.mainEmailId = this.currentUser?.email;
    this.getReportingList(this.page, this.itemsPerPage, this.selectedFilter);
  }

  ngOnDestroy() {
    this._header.welcomeUserMsg.next(false);
  }

  getCount() {
    this.dashboardService.getDashboardCount().subscribe((res: any) => {
      if (res.statusCode == 200) {
        this.registeredGuest = res.output.result;
        this.registeredHost = res.output.totalCount;
      }
    });
  }

  onChangeCategory(selected: string) {
    this.selectedFilter = selected;
    this.getReportingList(this.page, this.itemsPerPage, this.selectedFilter);
  }

  saveEndDate(event: any): void {
    this.endDate = event.value;
    const to = this.formatDate(this.endDate);
    const from = this.formatDate(this.startDate);
    this.getReportingList(this.page, this.itemsPerPage, 'RANGE', from, to);
  }

  formatDate(date: Date): string {
    return date.toISOString().split('T')[0];
  }

  getReportingList(page: number, pageLimit: number, timeRange: string, from?: string, to?: string) {
    console.log("Page Limit:", pageLimit);
    const data = {
      page: page,
      limit: pageLimit,
    };

    this.dashboardService.getDashboardList(timeRange).subscribe(
      (res: any) => {
        if (res.status) {
          this.registeredUsers = res.data.registeredUsers;
          this.resultCount = res.output.totalCount;
        }
      },
      (error) => {
        console.error('Error:', error);
      }
    );
  }

  getFormattedNumber(value: any) {
    this.formattedNumber = value.toLocaleString('en-US');
    document.getElementById("client12").innerHTML = this.formattedNumber;
    const numberFormat = this.formattedNumber;
    console.log(this.formattedNumber);
    return numberFormat;
  }

  getCounter() {
    const formattedNumber = this.totalRevenue.toLocaleString('en-US');
    document.getElementById("client1").innerHTML = formattedNumber;
    console.log(formattedNumber);
  }

  pageChanged(event: PageChangedEvent): void {
    this.page = event.page;
    this.getReportingList(event.page, event.itemsPerPage, this.selectedFilter);
    (<HTMLInputElement>document.getElementById("search")).value = '';
    window.scrollTo(0, 0);
  }
}
